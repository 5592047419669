import { render, staticRenderFns } from "./ShopChophandTabe.vue?vue&type=template&id=f83d3a2a&scoped=true&"
import script from "./ShopChophandTabe.vue?vue&type=script&lang=js&"
export * from "./ShopChophandTabe.vue?vue&type=script&lang=js&"
import style0 from "./ShopChophandTabe.vue?vue&type=style&index=0&id=f83d3a2a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f83d3a2a",
  null
  
)

export default component.exports